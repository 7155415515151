import React, { useState } from "react";
import Icons from "../../Constants/Icons";
import Domains from "../../Constants/domain";
import { loadCommonLogo ,LoginCommonBannerImages} from "../../globalimport";

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({ username: "", password: "" });

    const handleLogin = (e) => {
        e.preventDefault();
        const newErrors = {};
        if (!username) newErrors.username = "Please enter username";
        if (!password) newErrors.password = "Please enter password";
    };


    return (
        <div
            style={{
                backgroundImage: `url(${LoginCommonBannerImages()})`,
                minHeight: "100%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                height: "100%",
                width: "100%",
                position: "fixed",
                overflowY:"auto",
                overflowX:"hidden"

            }}
        >
            <form onSubmit={handleLogin} id="formLogin">
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12 offset-sm-0 offset-lg-4 p-sm-5" style={{margin:"auto"}}>
                        <div className="team-item p-lg-5 p-sm-3">
                            <div style={{textAlign:"center"}}
                            >
                                <img
                                    src={loadCommonLogo()}
                                    alt="Mystery Management"
                                />
                            </div>
                            <div className="team-content">
                                <br />
                                <h3 className="box-title m-b-20 text-center">Sign In</h3>
                                <br />
                                <div className="form-group">
                                    <div className="col-xs-12">
                                        <input
                                            name="txtUN"
                                            type="text"
                                            id="txtUN"
                                            className="form-control"
                                            placeholder="Username"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                        />
                                        {errors.username && (
                                            <span className="text-danger">{errors.username}</span>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col-xs-12">
                                        <input
                                            name="txtPass"
                                            type="password"
                                            id="txtPass"
                                            className="form-control"
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        {errors.password && (
                                            <span className="text-danger">{errors.password}</span>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group text-center m-t-20">
                                    <div className="col-xs-12">
                                        <button
                                            type="submit"
                                            className="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light mb-0"
                                        >
                                            Login
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Login;
