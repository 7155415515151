let Domains;

// if (window.location.hostname.includes('location') || window.location.hostname === '127.0.0.1') {
//   Domains = {
//     ALDI: 'aldi.com',
//     BLOOMS: 'blooms.com',
//     MM: 'mm.com',
//   };
 
// } else {
//   Domains = {
//     ALDI: 'aldimm.com',
//     BLOOMS: 'bloomsmm.com',
//     MM: 'mmmm.com',
//   };
// }

// Domains = {
//     ALDI: 'aldi.com',
//     BLOOMS: 'blooms.com',
//     MM: 'mm.com',
//   };
 
  
Domains = {
  ALDI: 'aldi.complyco.com.au',
  BLOOMS: 'blooms.complyco.com.au',
  MM: 'complyco.com.au',
};

export default Domains;