
import { Link } from "react-router-dom";
import { loadCommonLogo ,loadCommonBanner ,loadCommonBannerImages } from "../../globalimport";
import { useEffect ,useState} from "react";

function Home() {

  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const loadedImages = [
      loadCommonBanner(),
      loadCommonBannerImages(),
    ];
    setImages(loadedImages);
  }, []);
  useEffect(() => {
    if (images.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 3000);

      return () => clearInterval(interval); 
    }
  }, [images]);


  return (
    <div >
       <header className="header-area header-white" style={{ marginTop: '2em' ,}}>
      <div className="container">
        <div className="row m-0 p-0">
          <div className="col-12 p-0" style={{ height: '80px' }}>
            <nav className="main-nav">
              <a
                href="/"
                className="logo"
                style={{
                  background: 'white',
                  padding: '1em',
                  boxShadow: '0 2px 28px 0 rgba(98,171,172,.6)',
                  marginTop: '-1.5em',
                  borderRadius: '10px',
                }}
              >
                <img
                  className="lazy"
                  alt="mystery shopping services in Australia"
                  title="secret shopper in Australia"
                  src={loadCommonLogo()}
                />
              </a>
              <ul className="nav">
                <li style={{ display: 'none' }} className="active">
                  <a href="/">Home</a>
                </li>
                <li style={{ display: 'none' }} className="">
                  <a href="/register-as-secret-shopper">Become A Shopper</a>
                </li>
                <li style={{ paddingRight: '0', display: 'none' }} className="">
                  <a href="/contact-us">Contact</a>
                </li>
                  {/* <li
                    className="showmobile"
                    style={{
                      width: '100%',
                      display: 'inline-block',
                      textAlign: 'center',
                      background: '#001e78',
                    }}
                  >
                    <span style={{ verticalAlign: 'middle' }}>
                      <a
                        href="tel:+610295226965"
                        style={{
                          display: 'inline-block',
                          fontSize: 'calc(1.80em + 1vmin)',
                          paddingLeft: '0px !important',
                          background: 'transparent !important',
                          color: 'white !important',
                          marginBottom: '0.5em',
                          marginTop: '0.5em',
                          textShadow: '2px 3px #ffffff3b',
                        }}
                      >
                        +61 02952 26965
                      </a>
                    </span>
                  </li> */}
                {/* <li
                  className="showmobile"
                  style={{
                    display: 'block',
                    background: 'white!important',
                  }}
                >
                  <ul
                    id="socialicons"
                    style={{
                      width: '100%',
                      display: 'table',
                    }}
                  >
                    <li>
                      <a
                        href="https://www.facebook.com/mysteryshoppingsolutions/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ height: 'auto !important', textAlign: 'center' }}
                      >
                        <img
                          className="lazy"
                          alt="facebook"
                          title="facebook"
                          src="/images/facebook.svg"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/mysterymanagement/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="lazy"
                          style={{ height: '100%' }}
                          alt="instagram"
                          title="instagram"
                          src="/images/instagram.svg"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/mystery-management/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="lazy"
                          style={{ height: '100%' }}
                          alt="linkedin"
                          title="linkedin"
                          src="/images/linkedin.svg"
                        />
                      </a>
                    </li>
                  </ul>
                </li> */}
              </ul>
              <a className="menu-trigger">
                <span>Menu</span>
              </a>
            </nav>
          </div>
        </div>
      </div>
    </header>



    <div className="welcome-area" id="welcome">
      <div className="right-bg">
      {images.map((image, index) => (
          <img
            src={image}
            style={{
              width: "100%",
              height:"100%",
              zIndex: "99",
              position: "absolute",
              top: "0px",
              left: "0px",
              visibility: "visible",
              display: "block",
              opacity: 1,
              borderRadius:"50% 0 0 50%",
              visibility: index === currentIndex ? "visible" : "hidden",
              opacity: index === currentIndex ? 1 : 0,
              transition: "opacity 1s ease-in-out",



            }}
            className="sliderphoto img-fluid float-right cycle-slide cycle-slide-active"
            alt="Mystery Shopping"
            title="Mystery Shopping"
          />))}
         
      </div>

   

      <div className="header-text">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12 col-sm-12">
              <h1>Australia's #1 <br /> Mystery Shopping Provider</h1>
              <p>
                We help Australian businesses to improve their sales, by
                improving their customer experience.
              </p>
              <div className="row">
                <div className="col-sm-12 col-md-12 mb-sm-3 mt-sm-5 mt-md-0">
                  <Link
                    id="lboperations"
                    className="btn-primary-line"
                    to="/login"
                    style={{ background: "#fdbf00" }}
                  >
                    Shopper's Login
                  </Link>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-6 mb-sm-3">
                  <Link to="/login" className="btn-primary-line">
                    Aldi Login
                  </Link>
                  <input
                    type="submit"
                    name="btnShow"
                    value="Aldi Login"
                    // onClick={() => txfocus()}
                    id="btnShow"
                    className="btn-primary-line d-none"
                  />
                </div>
                <div className="col-sm-12 col-md-6 mb-sm-12 mt-sm-12 mt-md-0">
                  <Link to="/login" className="btn-primary-line">
                    Region Login
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row showmobile">
            <div className="col-sm-12 col-md-6 mb-sm-3">
              <a
                href="tel:+610295226965"
                style={{
                  marginBottom: "1em",
                  display: "inline-block",
                  fontSize: "30px",
                  background: "#fff !important",
                  color: "#001e78 !important",
                  padding: "0.4em 1em",
                  borderRadius: "5px",
                  boxShadow: "2px 2px 14px 2px rgb(45, 45, 49)",
                  fontWeight: "bolder",
                }}
              >
                <img
                  src="/images/phone.svg"
                  style={{
                    width: "30px",
                    marginTop: "-15px",
                    marginRight: "10px",
                  }}
                />
                +61 02952 26965
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* ***** Header Text End ***** */}
    </div>

    </div>
  );
}
export default Home;
