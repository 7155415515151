
import './bootstrap-icons.css';
import Home from './Pages/Home/Home';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Login from './Pages/Login/Login';
import { loadDomainSpecificCSS } from './globalimport'; 
import "./App.css"
function App() {
  useEffect(() => {
    loadDomainSpecificCSS();

  });
  return (
    <div className="App">
   
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/*"
          element={<Navigate to="/" replace={true} />}
        />
      </Routes>
    </div>
  );
}
export default App;
