import './bootstrap-icons.css';
import Domains from './Constants/domain';

export const loadDomainSpecificCSS = () => {
  const domain = window.location.hostname;

  if (domain.includes(Domains.ALDI)) {
    import('../src/assets/aldi/css/style.css').catch((err) => {
      console.error("Error loading CSS for ALDI", err);
    });
  } else if (domain.includes(Domains.BLOOMS)) {
    import('../src/assets/blooms/css/style.css').catch((err) => {
      console.error("Error loading CSS for BLOOMS", err);
    });
  } else if (domain.includes(Domains.MM)) {
    import('../src/assets/common/css/style.css').catch((err) => {
      console.error("Error loading CSS for MM", err);
    });
  } else {
    import('../src/assets/common/css/style.css').catch((err) => {
      console.error("Error loading default CSS", err);
    });
  }
};


export const loadCommonLogo = () => {
  const domain = window.location.hostname;

  if (domain.includes(Domains.ALDI)) {
    return require('../src/assets/aldi/images/aldimm-logo.png');
  } else if (domain.includes(Domains.BLOOMS)) {
     return require('../src/assets/blooms/images/bloomsmm-logo.png');
  } else if (domain.includes(Domains.MM)) {
    return require('../src/assets/aldi/images/aldimm-logo.png');
  } else {
  //   return require('../src/assets/common/images/default-image.jpg');
  }
};


export const loadCommonBanner = () => {
  const domain = window.location.hostname;

  if (domain.includes(Domains.ALDI)) {
    return require('../src/assets/aldi/images/Aldi-Banner1.jpg');
  } else if (domain.includes(Domains.BLOOMS)) {
     return require('../src/assets/blooms/images/Blooms-banner2.png');
  } else if (domain.includes(Domains.MM)) {
    return require('../src/assets/aldi/images/Aldi-Banner1.jpg');
  } else {
  //   return require('../src/assets/common/images/default-image.jpg');
  }
};

export const loadCommonBannerImages = () => {
  const domain = window.location.hostname;

  if (domain.includes(Domains.ALDI)) {
    return require('../src/assets/aldi/images/Aldi-Banner2.jpg');
  } else if (domain.includes(Domains.BLOOMS)) {
     return require('../src/assets/blooms/images/Blooms-banner1.png');
  } else if (domain.includes(Domains.MM)) {
  //   return require('../src/assets/common/images/default-image.jpg');
  } else {
  //   return require('../src/assets/common/images/default-image.jpg');
  }
};

export const LoginCommonBannerImages = () => {
  const domain = window.location.hostname;

  if (domain.includes(Domains.ALDI)) {
    return require('../src/assets/aldi/images/loginbgnew.jpg');
  } else if (domain.includes(Domains.BLOOMS)) {
     return require('../src/assets/blooms/images/Blooms.webp');
  } else if (domain.includes(Domains.MM)) {
  //   return require('../src/assets/common/images/default-image.jpg');
  } else {
  //   return require('../src/assets/common/images/default-image.jpg');
  }
};